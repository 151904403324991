import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Acumin } from '../common/Acumin'
import { Image } from '../common/Image'
const logoImageSrc = require('../../images/footer_logo.png')

export const Footer = () => {
  return (
    <>
      <Container>
        <LinkContainer>
          <Link to='privacy'><Acumin light>Privacy Policy</Acumin></Link>
          <Link to='law'><Acumin light>Act on Specified Commercial Transactions</Acumin></Link>
          <Link to='term'><Acumin light>User Policy</Acumin></Link>
        </LinkContainer>

        <BottomContainer>
          <Copyright>
            © Copyright {new Date().getFullYear()}. powered by INSPIRATION CULT MAG. InspirationCult theme by Witplaats.
            </Copyright>
          <FooterLogoWrapper>
            <a href="https://www.inspirationcult.net/">
              {/* <Image path='footer_logo.png' /> */}
              <img src={logoImageSrc} alt="logo" />
            </a>
          </FooterLogoWrapper>
        </BottomContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const LinkContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 12px 0;
    padding: 0 60px;

    * {
      font-size: 1rem;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 700px;
    margin: 24px auto;

    * {
      font-size: 2.6rem;
    }
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const BottomContainer = styled.div`
  background-color: #000;
`

const Copyright = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 8px;
    height: 32px;
    line-height: 32px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    height: 64px;
    line-height: 64px;
  `}

  color: #fff;
  font-family: '小塚ゴシック Pro','Kozuka Gothic Pro',sans-serif;
  text-align: center;
  letter-spacing: -0.5px;
`

const FooterLogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    text-align: center;
    padding: 16px 0;

    img {
      object-fit: contain !important;
      width: 200px !important;
      margin: 0 auto;
      display: block;
    }
  `}
  ${customMedia.greaterThan("medium")`
    position: absolute;
    right: 24px;
    bottom: 10px;
    width: 150px;
  `}
`
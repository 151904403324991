import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'
import { Window } from '../common/Window'

import { StoreContext } from '../../context/StoreContext'

const countQuantity = lineItems => {
	let quantity = 0

	lineItems.forEach(item => {
		quantity = quantity + item.quantity
	});

	return quantity
}

export const Header = () => {
  const { removeLineItem, store: { client, checkout } } = useContext(StoreContext)
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))

	useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])
  
  const handleEmptyCart = () => {
    if (quantity === 0) {
      window.alert('カートは空です。')
    } else {
      if (window.confirm('カートを空にします。よろしいですか？')) {
        checkout.lineItems.forEach(lineItem => {
          removeLineItem(client, checkout.id, lineItem.id)
        })
      }
    }
  }

  const handleCheckout = () => {
    if (quantity === 0) {
      window.alert('カートは空です。')
    } else {
      // @ts-ignore
      window.open(checkout.webUrl)
    }
  }
  
  return (
    <>
      <WindowWrapper>
        <Window heightSp={36} widthSp={236} heightPc={48} widthPc={450}>
          <WindowContent>
            <LogoWrapper>
              <Link to='/'>
                <Image path='header_logo@2x.png' />
              </Link>
            </LogoWrapper>

            <CartLogoContainer>
              <CartLogoWrapper onClick={handleEmptyCart}>
                <Image path='cart@2x.png' />
                <Quantity>×</Quantity>
              </CartLogoWrapper>
              
              <CartLogoWrapper onClick={handleCheckout}>
                <Image path='cart@2x.png' />
                <Quantity>{quantity}</Quantity>
              </CartLogoWrapper>
            </CartLogoContainer>
          </WindowContent>
        </Window>
      </WindowWrapper>
    </>
  )
}

const WindowWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    top: 20px;
    right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    top: 40px;
    right: 40px;
  `}

  position: fixed;
  z-index: 999;
`

const WindowContent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const LogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 12px;
    width: 160px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 24px;
    width: 300px;
  `}
`

const CartLogoContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
`


const CartLogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-right: 10px;
    width: 12px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-right: 20px;
    width: 24px;
  `}

  cursor: pointer;
  position: relative;
`

const Quantity = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 5px;
    top: -3px;
    left: -3px;
    height: 8px;
    width: 8px;
    padding: 1px 0 0 1px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1rem;
    top: -6px;
    left: -6px;
    height: 16px;
    width: 16px;
    padding: 0.5px 0 0 0.5px;
  `}

  background-color: #e72b23;
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  text-align: center;
`
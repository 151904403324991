/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import styled from 'styled-components'
import { customMedia } from '../common/customMedia'

import { ContextProvider } from '../../provider/ContextProvider'
import { Header } from '../Header'
import { Footer } from '../Footer'
import './layout.css'

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ContextProvider>
        <Header />
        <main>{children}</main>
        <Footer />     
      </ContextProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
import React from 'react'
import { Client as ClientType, Cart, LineItem } from 'shopify-buy'

export interface Store {
  client: ClientType
  adding: boolean
  checkout: Cart
}

export interface Context {
  store: Store
  addVariantToCart: (variantId: any, quantity: any) => Promise<void>
  removeLineItem: (client: any, checkoutID: any, lineItemID: any) => any
  updateLineItem: (client: any, checkoutID: any, lineItemID: any, quantity: any) => any
}

export const StoreContext = React.createContext({} as Context)
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../customMedia'

export interface Props {
  heightSp: number
  widthSp: number
  heightPc: number
  widthPc: number
}

export interface OwnProps {
  children: ReactNode
}

export const Window = (props: Props & OwnProps) => {
  const { heightSp, widthSp, heightPc, widthPc } = props
  const { children } = props

  return (
    <>
      <Container widthSp={widthSp} widthPc={widthPc}>
        <Shadow heightSp={heightSp} widthSp={widthSp} heightPc={heightPc} widthPc={widthPc} />

        <ToolBar>
          <ButtonWrapper>
            <Button />
            <Button />
          </ButtonWrapper>
        </ToolBar>

        <Content heightSp={heightSp} widthSp={widthSp} heightPc={heightPc} widthPc={widthPc}>
          {children}
        </Content>
      </Container>
    </>
  )
}

const Container = styled.div<{ widthSp: number, widthPc: number }>`
  ${props => customMedia.lessThan("medium")`
    width: ${props.widthSp ? props.widthSp : props.widthPc/2}px;
  `}
  ${props => customMedia.greaterThan("medium")`
    width: ${props.widthPc}px;
  `}

  position: relative;
`

const ToolBar = styled.div<{ widthSp: number, widthPc: number }>`
  ${props => customMedia.lessThan("medium")`
    border: 4px #000 solid;
    border-bottom: none;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    height: 18px;
    width: ${props.widthSp ? props.widthSp : props.widthPc/2}px;
  `}
  ${props => customMedia.greaterThan("medium")`
    border: 4px #000 solid;
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 30px;
    width: ${props.widthPc}px;
  `}

  background-color: #fff;
  background-image: radial-gradient(#000000 20%, transparent 20%),
    radial-gradient(#000000 20%, transparent 20%);
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
  position: relative;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    height: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 30px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  right: 10px;
  position: absolute;
`

const Button = styled.div`
  ${customMedia.lessThan("medium")`
    height: 5px;
    margin: 0 2px;
    width: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 10px;
    margin: 0 2px;
    width: 10px;
  `}

  background-color: #000;
  border-radius: 50%;
`

const Content = styled.div<{ heightSp: number, widthSp: number, heightPc: number, widthPc: number }>`
  ${props => customMedia.lessThan("medium")`
    border: 4px #000 solid;
    height: ${props.heightSp ? props.heightSp : props.heightPc/2}px;
    width: ${props.widthSp ? props.widthSp : props.widthPc/2}px;
  `}
  ${props => customMedia.greaterThan("medium")`
    border: 4px #000 solid;
    height: ${props.heightPc}px;
    width: ${props => props.widthPc}px;
  `}
  
  background-color: #fff;
  position: relative;
`

const Shadow = styled.div<{ heightSp: number, widthSp: number, heightPc: number, widthPc: number }>`
  ${props => customMedia.lessThan("medium")`
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    height: ${props.heightSp ? props.heightSp + 15 : props.heightPc/2 + 15}px;
    width: ${props.widthSp ? props.widthSp : props.widthPc/2}px;
    top: 6px;
    right: -6px;
  `}
  ${props => customMedia.greaterThan("medium")`
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: ${props.heightPc + 30}px;
    width: ${props.widthPc}px;
    top: 6px;
    right: -6px;
  `}

  position: absolute;
  background-color: #000;
  width: 100%;
`
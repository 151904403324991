import styled from 'styled-components'
import { customMedia } from '../customMedia'

export const Acumin = styled.span<{light?: boolean, white?: boolean}>`
  ${customMedia.lessThan("medium")`
    font-size: 4rem;
    font-variation-settings: 'wght' 680, 'wdth' 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 6rem;
    font-variation-settings: 'wght' 660, 'wdth' 35;
  `}

  color: ${props => props.white ? '#fff' : '#000'};
  font-family: 'AcuminVariableConcept';
  font-weight: ${props => props.light ? 'normal' : 'bold' };
`